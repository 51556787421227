<template>
  <main class="max-w-screen-2xl mx-auto pt-8 sm:pt-12">
    <h1 class="px-4 max-w-4xl text-4xl font-heading md:px-12 md:text-5xl">
      Il y en a pour tous les goûts dans notre menu
    </h1>

    <section class="px-4 mt-20 relative lg:px-8">
      <div class="relative sm:flex">
        <div class="relative">
          <button
            v-show="selectedCategory"
            class="bg-gray-50 rounded-full p-1 border border-gray-400 absolute -right-2 -top-3"
            @click="selectedCategory = undefined"
          >
            <span class="sr-only">Effacer les filtres</span>
            <XIcon class="w-4 h-4 text-gray-600" />
          </button>
          <AppInput
            id="filterByCategory"
            type="select"
            label="Catégories"
            :srOnlyLabel="true"
            v-model="selectedCategory"
          >
            <option
              v-for="option in categories"
              :key="option.id"
              :value="option.name"
              :selected="selectedCategory === option.name"
            >
              {{ option.name }}
            </option>
          </AppInput>
        </div>
      </div>

      <Skeleton :show="loading || offersLoading" class="mt-4 sm:mt-8">
        <OffersList v-if="selectedCategory === 'Formules'" :offers="offers" />
        <ProductsList v-else :products="products" />

        <template #fallback>
          <ProductListSkeleton />
        </template>
      </Skeleton>

      <Pagination
        class="mt-8"
        linkName="Shop"
        v-model:page="page"
        :total="getItemsTotal()"
        :itemsPerPage="itemsPerPage"
      />
    </section>

    <NewsLetter class="px-6 md:px-10" />
  </main>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { RouteLocationNormalizedLoaded, useRoute, useRouter } from 'vue-router';
import { XIcon } from '@heroicons/vue/outline';

import ProductsList from '@/components/product/ProductsList.vue';
import Pagination from '@/components/shared/Pagination.vue';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import ProductListSkeleton from '@/components/skeleton/ProductListSkeleton.vue';
import AppInput from '@/components/shared/AppInput.vue';
import NewsLetter from '@/components/home/NewsLetter.vue';
import useToast from '@/hooks/use-toast';
import useProducts from '@/hooks/products/use-products';
import useCategories from '@/hooks/categories/use-categories';
import useOffers from '@/hooks/offers/use-offers';
import OffersList from '@/components/offers/OffersList.vue';

const getPageFromQuery = (query: RouteLocationNormalizedLoaded['query']) => {
  return !query.page ? 1 : +query.page <= 0 ? 1 : +query.page;
};

const getCategoryFromQuery = (query: RouteLocationNormalizedLoaded['query']) => {
  return Array.isArray(query.category) ? query.category[0] : query.category?.toString();
};

export default defineComponent({
  components: {
    ProductsList,
    Skeleton,
    ProductListSkeleton,
    Pagination,
    AppInput,
    XIcon,
    NewsLetter,
    OffersList,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const itemsPerPage = 9;
    const page = ref(getPageFromQuery(route.query));
    const selectedCategory = ref(getCategoryFromQuery(route.query));
    const { showToast } = useToast();
    const { products, count, filteredProductsCount, useAllProducts } = useProducts();
    const { getAllProducts, loading, errors } = useAllProducts();
    const { categories } = useCategories();
    const { offers, count: offersCount, useAllOffers } = useOffers();
    const { getAllOffers, errors: offersError, loading: offersLoading } = useAllOffers();

    const searchItems = async (page: number, newCategory: string | null = null) => {
      if (page >= 1) {
        const offset = page === 1 ? 0 : (page - 1) * itemsPerPage;

        if (newCategory === 'Tous les produits') {
          await getAllProducts({ start: offset, limit: itemsPerPage });
        } else if (newCategory === 'Formules') {
          await getAllOffers({
            start: offset,
            limit: itemsPerPage,
          });
        } else {
          await getAllProducts({
            start: offset,
            limit: itemsPerPage,
            filter: newCategory ? `categories.name_contains=${newCategory}` : undefined,
          });
        }

        if (errors.value.length) {
          errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
        } else if (offersError.value.length) {
          offersError.value.forEach((error) =>
            showToast({ message: error.message, type: 'failure' })
          );
        } else {
          router.push({
            name: 'Shop',
            query: { page, category: selectedCategory.value },
          });
        }
      }
    };

    searchItems(page.value, selectedCategory.value);

    watch(page, (newPage) => searchItems(newPage, selectedCategory.value));
    watch(selectedCategory, (newCategory, previousCategory) => {
      if (newCategory !== previousCategory) {
        searchItems(1, newCategory);
      } else {
        searchItems(page.value, newCategory);
      }
    });

    watch(route, (newRoute) => {
      const category = getCategoryFromQuery(newRoute.query);
      if (newRoute.name === 'Shop' && category && category !== selectedCategory.value) {
        selectedCategory.value = category;
      }
    });

    const getItemsTotal = () => {
      if (selectedCategory.value === 'Formules') {
        return offersCount.value;
      } else {
        return count.value ? count.value : filteredProductsCount.value;
      }
    };

    return {
      loading,
      products,
      categories,
      selectedCategory,
      itemsPerPage,
      page,
      offers,
      offersLoading,
      getItemsTotal,
    };
  },
});
</script>
